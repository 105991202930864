/* You can add global styles to this file, and also import other style files */

/* COLORS */
.primary {
    color: #FFB81C !important;
}

/* GRADIENTS */
.gradient-cdcells{
    background: rgb(134,84,17);
    background: linear-gradient(90deg, rgba(134,84,17,1) 0%, rgba(241,206,105,1) 50%, rgba(134,84,17,1) 100%);
}

/* BACKGROUND */
.bg-primary {
    background-color: #FFB81C !important;
}

/* BUTTONS */
.btn-primary{
    color: #fff;
    background-color: #FFB81C;
    border-color: #FFB81C;
}
.btn-primary:hover{
    color: #fff;
    background-color: #845600;
    border-color: #845600;
}
.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: rgb(122, 122, 122);
    border-color: rgb(122, 122, 122);;
}

.bg-light-primary {
    background-color: #FDF8D7 !important;
    color: #FFB81C !important;
    border-color: #FDF8D7;
}
.btn.bg-light-primary:hover {
    color: #fff;
    background-color: #FFB81C !important;
    border-color: #FFB81C;
}
.btn-outline-primary, .btn-outline-primary:focus, .btn-outline-primary:after {
    border: 1px solid;
    border-color: #FFB81C;
    background-color: transparent;
    color: #FFB81C;
}
.btn-outline-primary:hover {
    border: 1px solid;
    border-color: #FFB81C;
    background-color: #FFB81C !important;
    color: #fff;
}


/* LINK */

a {
    color: #FFB81C;
    text-decoration: none;
    background-color: transparent;
}
a:hover, a:focus {
    color: #FDF8D7;
    text-decoration: none;
    background-color: transparent;
}

/* TEXT */

.text-primary, a.text-primary:hover {
    color: #FFB81C !important;
}
.text-info {
    color: #FDF8D7 !important;
}
.ft-search:before{
    color: #FDF8D7 !important;
}

/* INPUTS */

.checkbox input[type=checkbox]:checked ~ label:after {
    border-color: #FFB81C !important;
}

textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    border-color: #FFB81C !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #FFB81C;
    background-color: #FFB81C;
}

/*ACTIVE LINE MENU COLOR*/
body[data-menu=vertical-menu] .app-sidebar .navigation li.active > a:before {
    border-right: solid 5px #FFB81C;
}

/*LOGO WIDTH*/
.app-sidebar .logo-img {
    width: 100% !important;
    display: inline-block;
    /* margin-right: 15px; */
}

.logo-img img{
    /* margin-top: -20px; */
    margin-bottom: 10px;
    /* width: 100 !important; */
    /* max-height: 40px; */
}

.app-sidebar .logo {
    padding: 0.2rem 0.2rem 0.2rem 0.2rem !important;
}

nav.navbar.header-navbar{
    background-color: rgb(38,38,38) !important;
}

/* NAV TABS */
.nav-tabs .nav-link.active {
    border-bottom: 2px solid #FDF8D7;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: rgb(134,84,17);
}

/* PAGINATION */
body.layout-dark .pagination .page-item.active .page-link {
    background-color: #FFB81C !important;
    border-color: #FFB81C !important;
}
.page-link {
    color: #FFB81C;
}
.page-link:hover {
    color: #FDF8D7;
}